import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BreadcrumbPage from "../../Components/SearchResult/BreadcrumbPage/BreadcrumbPage";
import PropertyDetailsBanner from "../../Components/PropertyDetails/PropertyDetailsBanner/PropertyDetailsBanner";
import PropertyDescription from "../../Components/PropertyDetails/PropertyDescription/PropertyDescription";
import StationLocations from "../../Components/PropertyDetails/StationLocations/StationLocations";
import Calculator from "../../Components/PropertyDetails/Calculator/Calculator";
import FeaturedProperties from "../../Components/PropertyDetails/FeaturedProperties/FeaturedProperties";
const PropertyDetails = () => {
    return (
        <React.Fragment>
            {/* <div className="header-logo-black"><Header/></div> */}
            {/* <PropertyDetailsBanner/> */}
            {/* <PropertyDescription/> */}
            {/* <StationLocations/> */}
            {/* <Calculator/> */}
            {/* <FeaturedProperties/> */}
            {/* <div className="breadcrumb-other-page"><BreadcrumbPage/></div> */}
            {/* <Footer/> */}
        </React.Fragment>
    )
}

export default PropertyDetails