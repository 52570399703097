import React, { useEffect, useState } from "react"
import { Link } from "@StarberryUtils";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { Button, Form, Container, FormControl, Row, InputGroup, Col, Dropdown, Tabs, Tab, NavItem } from "react-bootstrap";
import "./FeaturedProperties.scss";
// import FsLightbox from 'fslightbox-react';
import axios from 'axios';
import { numberWithCommas } from "@Components/common/site/common-functions.js"
import FsLight from '@Components/SimilarProperties/FsLight';
import FloorPlanModal from "@Components/FloorPlanModal/floorPlanModal";
import CustomVideoPlay from "@Components/CustomVideo/CustomVideo.js";
import {
  FORSALE_PAGE_URL,
  TORENT_PAGE_URL
} from "@Components/common/site/constants";
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";

import {ImageModule} from "@Components/common/Image_Module";


export const uknumberformat = (x) => {
  if (!x) return x; 
  const currentValue = x.toString().replace(/[^\d]/g, '');
  return `${currentValue.slice(0, 5)} ${currentValue.slice(5, 11)}`; 
}

const FeaturedProperties = (props) => {
  


  const [salespropItems, salessetPropItems] = useState([])
  const [rentpropItems, rentsetPropItems] = useState([])
  const getitemssales = async url => {
    try {
      const { data } = await axios.get(url, {
              headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
              }});
      salessetPropItems(data)
    } catch (error) {
    }
  }
  const getitemsrent = async url => {
    try {
      const { data } = await axios.get(url, {
              headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
              }});
      rentsetPropItems(data)      
    } catch (error) {

    }
  }
  const [lightboxController] = useState({
    toggler: false,
    slide: 1
  });
  const [imagesArr] = useState([])

  const [isrentpage, setisrentpage] = useState(false)

  useEffect(() => {
    let area = props.area ? props.area : "";
    let saleurl = process.env.GATSBY_STRAPI_SRC + "/properties?search_type=sales&_limit=4&_sort=price:DESC&publish=true"; // TODO: base URL supposed to be from .env
    let renturl = process.env.GATSBY_STRAPI_SRC + "/properties?search_type=lettings&_limit=4&_sort=price:DESC&publish=true"; // TODO: base URL supposed to be from .env
    if (area) {
      saleurl = saleurl + "&display_address_contains=" + area;
      renturl = renturl + "&display_address_contains=" + area;
    }

    getitemssales(saleurl)
    getitemsrent(renturl)

    let urlPathName = '';
  
  if (typeof window !== 'undefined') {
    urlPathName = window.location.pathname;
    urlPathName = urlPathName.toLowerCase();
    if ( urlPathName && urlPathName.indexOf('landlords') > -1 ) {
      setisrentpage(true)
    }
    if ( urlPathName && urlPathName.indexOf('tenants') > -1 ) {
      setisrentpage(true)
    }
  
  }

  }, [])
// console.log(salespropItems)
// console.log(rentpropItems)
  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 9999,

      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  }
  return (salespropItems && salespropItems.length > 0 || rentpropItems && rentpropItems.length > 0 ?
    <React.Fragment>
      <section className="featured-properties">
        <ScrollAnimation animateOnce={true} animateIn='fadeInUp'>
          <Container>
            <Row>
              <Col lg={12}>
                <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                  <h2>Featured Properties in {props.area}</h2>
                </ScrollAnimation>
              </Col>
            </Row>
            <Row className="feature-properties-row">
              <Col lg={12}>
                <Tabs defaultActiveKey={isrentpage || (salespropItems.length === 0 && rentpropItems.length > 0) ? "property-to-rent-in-oxford" : "property-for-sale-in-oxford"} id="uncontrolled-tab-example">
                  {salespropItems && salespropItems.length > 0 ?
                    <Tab eventKey="property-for-sale-in-oxford" title={"Property for Sale in " + props.area}>
                      <Slider {...settings}>
                        {salespropItems && salespropItems.length > 0 ? salespropItems.map((hit, ind) => {
                          let processedImages = JSON.stringify({});
                          if (hit?.imagetransforms?.Image_Transforms) {
                            processedImages = hit.imagetransforms.Image_Transforms;
                          }
                          return (

                            <div>
                              <div className="featured-property-card-main">
                                <div className="featured-property-img-content">
                                  <div className="feature-property-img-wrap img-hover-zoom video-wrap">
                                    <Link to={FORSALE_PAGE_URL.alias + '/' + hit.slug + '-' + hit.id}>
                                      {/* <picture>
                                        <source media="(min-width:992px)" srcSet={hit.images[0].url} />
                                        <source media="(min-width:768px)" srcSet={hit.images[0].url} />
                                        <img src={hit.images[0].url} alt="" />
                                        </picture> */}
                                        <ImageModule ImageSrc={hit.images[0]} attr={{ alt: hit.display_address }} ggfx_results={hit.ggfx_results} imagename="property.images.similarpropertytile" strapi_id={hit.id} />

                                      {/* <ImageTransform imagesources={hit.images[0]?.url} renderer="srcSet" imagename='property.images.similarpropertytile'
                                        attr={{ alt: '', className: '' }}
                                        imagetransformresult={processedImages} id={hit.id} >

                                      </ImageTransform> */}
                                    </Link>
                                  </div>
                                  <div className="icon-group">
                                    <ul className="img-icons">
                                      {hit?.floorplan[0]?.url && <FloorPlanModal Image_Transforms={hit?.imagetransforms?.Image_Transforms} id={hit.id} floorplanimg={hit?.floorplan[0]?.url} />}
                                      {hit?.virtual_tour ?
                                        <CustomVideoPlay small={true} title={hit?.title} url={hit?.virtual_tour} /> : null}
                                      <FsLight images={hit.images} />
                                      <li><span>{hit.images?.length}</span></li>
                                    </ul>
                                  </div>

                                </div>
                                <div className="featured-property-text-content">
                                  <Link to={FORSALE_PAGE_URL.alias + '/' + hit.slug + '-' + hit.id}>
                                    <div className="property-amount-text">
                                      <span className="amount">&#163;{numberWithCommas(hit.price)}</span>
                                      {hit.price_qualifier ? <span className="guide-text">{hit.price_qualifier}</span> : null}
                                    </div>
                                    <div className="property-description">
                                      <p>{hit.display_address}</p>
                                    </div>
                                    {/* <div className="item-user-details">
                                            <span className="contact-number">
                                            <picture>
                                            <source media="(min-width:992px)" srcSet={hit.images[0].url} />
                                            <source media="(min-width:768px)" srcSet={hit.images[0].url} />
                                            <img src={hit.images[0].url} alt="" />
                                            </picture>  
                                            <Link href={`tel: ${item.ContactNumber}`} class="btn-text-link">{item.ContactNumber}</Link>
                                            </span>
                                            <span class="wishlist  icon-round-box"><a href="#"><span class="icon-wrap"><i class="icon-wishlist-small"></i></span></a></span>
                                            <span className="text-save d-none d-md-inline-flex"><Link href="#">Save</Link></span>
                                        </div> */}
                                  </Link>
                                </div>
                              </div>
                            </div>

                          )
                        }) : ''}
                      </Slider>
                    </Tab>
                    : ''}
                  {rentpropItems && rentpropItems.length > 0 ?
                    <Tab eventKey="property-to-rent-in-oxford" title={"Property to Rent in " + props.area}>
                      <Slider {...settings}>
                        {rentpropItems && rentpropItems.length > 0 ? rentpropItems.map((hit, ind) => {
                          let processedImages = JSON.stringify({});
                          if (hit?.imagetransforms?.Image_Transforms) {
                            processedImages = hit.imagetransforms.Image_Transforms;
                          }
                          return (

                            <div>
                              <div className="featured-property-card-main">
                                <div className="featured-property-img-content">
                                  <div className="feature-property-img-wrap img-hover-zoom video-wrap">
                                    <Link to={TORENT_PAGE_URL.alias + '/' + hit.slug + '-' + hit.id}>
                                      {/* <picture>
                                        <source media="(min-width:992px)" srcSet={hit.images[0].url} />
                                        <source media="(min-width:768px)" srcSet={hit.images[0].url} />
                                        <img src={hit.images[0].url} alt="" />
                                        </picture> */}
                                        <ImageModule ImageSrc={hit.images[0]} attr={{ alt: hit.display_address }} ggfx_results={hit.ggfx_results} imagename="property.images.similarpropertytile" strapi_id={hit.id} />

                                      {/* <ImageTransform imagesources={hit.images[0]?.url} renderer="srcSet" imagename='property.images.similarpropertytile'
                                        attr={{ alt: '', className: '' }}
                                        imagetransformresult={processedImages} id={hit.id} >

                                      </ImageTransform> */}
                                    </Link>
                                  </div>
                                  <div className="icon-group">
                                    <ul className="img-icons">
                                      {hit?.floorplan[0]?.url && <FloorPlanModal Image_Transforms={hit?.imagetransforms?.Image_Transforms} id={hit.id} floorplanimg={hit?.floorplan[0]?.url} />}
                                      {/* {hit?.video_link ? <li><i class="icon-video-dark"></i></li> : null} */}
                                      {hit?.virtual_tour ?
                                        <CustomVideoPlay small={true} title={hit?.title} url={hit?.virtual_tour} /> : null}
                                      <FsLight images={hit.images} />
                                      <li><span>{hit.images?.length}</span></li>
                                    </ul>
                                  </div>

                                </div>
                                <div className="featured-property-text-content">
                                  <Link to={TORENT_PAGE_URL.alias + '/' + hit.slug + '-' + hit.id}>
                                    <div className="property-amount-text">
                                      <span className="amount">&#163;{numberWithCommas(hit.price)}</span>
                                      <span className="guide-text">PCM</span>
                                    </div>
                                    <div className="property-description">
                                      <p>{hit.display_address}</p>
                                    </div>
                                    <div className="item-user-details">
                                      <span className="contact-number">
                                        {/* <picture>
                                            <source media="(min-width:992px)" srcSet={hit.images[0].url} />
                                            <source media="(min-width:768px)" srcSet={hit.images[0].url} />
                                            <img src={hit.images[0].url} alt="" />
                                            </picture>   */}
                                        {hit?.crm_negotiator_id?.Telephone?.value &&
                                        <Link href={`tel: ${hit.crm_negotiator_id?.Telephone?.value}`} class="btn-text-link">{uknumberformat(hit.crm_negotiator_id?.Telephone?.value)}</Link>
                                        }
                                      </span>
                                      {/* <span class="wishlist  icon-round-box"><a href="#"><span class="icon-wrap"><i class="icon-wishlist-small"></i></span></a></span>
                                            <span className="text-save d-none d-md-inline-flex"><Link href="#">Save</Link></span> */}
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>

                          )
                        }) : ''}
                      </Slider>
                    </Tab> : ''}
                </Tabs>
              </Col>
            </Row>
          </Container>
        </ScrollAnimation>
      </section>
    </React.Fragment>
    : null)
}

export default FeaturedProperties;